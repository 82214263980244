<template>
  <div class="pricingItem">

  </div>

</template>

<script>

export default {
  name: 'PricingItem',

  props: {
    item: String
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
    }
  },
  methods: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header h1,
header h3 {
  color: aliceblue;
}

.pricingItem {
  width: 350px;
  min-height:25px;
  border: 2px solid silver;
}

a {
  color: #42b983;
}
</style>
