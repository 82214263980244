<template>
  <MainHeaderComponent msg="Contact"></MainHeaderComponent>
  <MainNavComponent></MainNavComponent>

  <div class="itemsNav">&nbsp;</div>
  <div class="pricingTable">
    <PricingItem :item="BlaBla"></PricingItem>
    <PricingItem :item="BlaBla"></PricingItem>
    <PricingItem :item="BlaBla"></PricingItem>
    <PricingItem :item="BlaBla"></PricingItem>

    <table>
    <tr>
      <td>Software Development:</td>
      <td>Starting from € 75,-/hr</td>
    </tr>
    <tr>
      <td>Virtual Assistance:</td>
      <td>Starting from €35,-/hr</td>
    </tr>
    <tr>
      <td>Training: Online course (exclusive):</td>
      <td>€ 35,-/hr</td>
    </tr>
    <tr>
      <td>Training: Online course (group 5+):</td>
      <td>€ 10,-/hr</td>
    </tr>
    <tr>
      <td>Training: presence (exclusive, 6 hr):</td>
      <td>€ 175,--</td>
    </tr>
  </table>
</div>

</template>

<script>
import MainHeaderComponent from "./MainHeaderComponent.vue";
import MainNavComponent from "./MainNavComponent.vue";
import PricingItem from "./PricingItem.vue";



export default {
  name: 'PricingComponent',

  components: {
    MainNavComponent,
    MainHeaderComponent,
    PricingItem
  },

  props: {
    msg: String
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
    }
  },
  methods: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header h1,
header h3 {
  color: aliceblue;
}

.itemsNav {
  margin: 60px auto;
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 800px;
  gap: 10px;
}

a {
  color: #42b983;
}
.pricingTable{
  margin: -40px auto;
background-color: white;
display: flex;
flex-direction: column;
justify-content: center;
width: 100%;
max-width: 800px;
height: 50vh;
}
</style>
