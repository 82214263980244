<template>
  <MainHeaderComponent msg="Virtual Assistance"></MainHeaderComponent>
  <MainNavComponent></MainNavComponent>
  <div class="itemsNav row">
    <button class="col-5 col-sm btn" :class="showItem == 1 ? 'btn-success' : 'btn-primary'" @click="showItem = 1">What
      is VA
      and Who is it for ?</button>
    <button class="col-5 col-sm btn" :class="showItem == 2 ? 'btn-success' : 'btn-primary'" @click="showItem = 2">My Offer as a VA</button>
    <!-- <button class="col-5 col-sm btn" :class="showItem == 3 ? 'btn-success' : 'btn-primary'"
      @click="showItem = 3">References</button> -->
  </div>
  <div class="va_what_who" v-if="showItem == 1">

    <div class="VaItems">
      <div class="itemBox row d-flex">
        <img class="imgWebdes d-sm-block d-nonl-sm-6" :src="va_teaser">

      </div>
      <div class="text-black pt-3 col-sm-6 col-12 p-5 m-3 p-sm-1 textBox">
        <h3>What is a Virtual Assistant <br />and Who Can Benefit?</h3>
        <p>
          A Virtual Assistant (VA) is far more than a remote secretary or receptionist. VAs are highly skilled
          professionals with expertise across various fields, ready to support your business in many ways. Whether you
          need a marketing strategist, language specialist, creative designer, or content writer, a VA can offer
          tailored solutions that fit your unique needs. With diverse backgrounds and experience, VAs provide a broad
          range of services that free up your time and allow you to focus on what truly matters: growing your business
          and reaching your goals.</p>
        <p>
          Harness the flexibility and expertise of a VA to boost your efficiency and achieve more.
        </p>

      </div>
    </div>


  </div>
  <div class="va_offer" v-if="this.showItem == 2">
    <div class="VaItems">
      <div class="itemBox row d-flex">
        <img class="imgWebdes d-sm-block d-nonl-sm-6" :src="va_teaser">

      </div>
      <div class="text-black pt-3 col-sm-6 col-12 p-5 m-3 p-sm-1 textBox">        <h3>What I Offer</h3>
        
          <h4>Excel Consulting and Spreadsheet Services:</h4>
          <p>Get the most out of your spreadsheets. </p>
        
          <h4>Automation:</h4>
          <p>  Do it more than once? Think about automation.</p>
       
        
          <h4>Process Optimization:</h4> 
          <p>  Cross-thinking for your business success. Where digital processes are not the prime focus, e.g. in gastronomy, sales or service. </p>
        
          <h4>Creative input:</h4> 
          <p>  Simplify your workload and enhance your business with fresh, unbiased insights.</p>
      </div>
      
    </div>
  </div>
  <div class="va_refs" v-if="showItem == 3">
    <div class="VaItems">

      <div class="text-black pt-3 col-12 p-3 textBox">
        <h3>Client Testimonials</h3>

        <slide-show v-if="showRef=1" class="slideShowBox" />


      </div>

    </div>
  </div>
</template>

<script>
import MainHeaderComponent from "./MainHeaderComponent.vue";
import MainNavComponent from "./MainNavComponent.vue";
import SlideShow from './SlideShow.vue';


export default {
  name: 'VaComponent',

  components: {

    MainNavComponent,
    MainHeaderComponent,
    SlideShow
  },

  props: {
    msg: String
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
      va_teaser: "/img/va_teaser.webp",
      showItem: 1,
      showRef:0,
    }
  },

  methods: {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header h1,
header h3 {
  color: aliceblue;
}

.imgWebdes {
  height: inherit;
  align-self: flex-start;

}

.itemsNav {
  margin: 60px auto;
  display: flex;
  justify-content: space-between;
  width: 80%;
  max-width: 800px;
  gap: 10px;
}


a {
  color: #42b983;
}

.VaItems {
  margin: -40px auto;
  background-color: white;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  max-width: 800px;
  height: 50vh;

}

.itemBox {
  display: flex;
  height: inherit;
  width: inherit;
  justify-content: start;

}

.itemListBox {
  padding-right: 15px;
  overflow-y: auto;
  overflow-x: none;
  text-wrap: wrap;
  text-align: left;
}

.itemListBox ul {
  list-style: none;
  padding: 0;
}

.itemListBox ul li {
  display: flex;
  align-items: flex-start;
}

.itemListBox ul li::before {
  content: "\2713";
  /* Unicode for checkmark */
  color: green;
  /* Optional: Change the color of the checkmark */
  font-weight: bold;
  /* Optional: Make the checkmark bold */
  display: inline-block;
  width: 1em;
  /* Adjust space between checkmark and text */
  margin-right: 0.2em;
  /* Adjust space between checkmark and text */
}

.itemListBox li {
  padding-left: 0.2em;
  /* Ensure text starts after checkmark */
}

.textBox {
  padding: 5px;
  background-color: none;
}

.textBox h4{
  font-weight:bold;
  font-size:1.0em;
  text-align:left;
}
.slideShowBox {
  height: 100%;
}
</style>
