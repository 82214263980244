<template>
   <div class="pageNav d-md-flex justify-content-around">
    <button class="btn col-12 col-md-2 mb-1" :class = "this.$root.currPage==1?'btn-success':'btn-primary'" @click="this.$root.currPage = 1">Software Development</button>
    <button class="btn col-12 col-md-2 mb-1" :class = "this.$root.currPage==2?'btn-success':'btn-primary'" @click="this.$root.currPage = 2">IT - Training</button>
    <!-- <button class="btn col-12 col-md-3 mb-1" :class = "this.$root.currPage==4?'btn-success':'btn-primary'" @click="this.$root.currPage = 4">Blog</button> -->
    <button class="btn col-12 col-md-2 mb-1" :class = "this.$root.currPage==5?'btn-success':'btn-primary'" @click="this.$root.currPage = 5">Virtual Assistance</button>
    <!-- <button class="btn col-12 col-md-2 mb-1" :class = "this.$root.currPage==6?'btn-success':'btn-primary'" @click="this.$root.currPage = 6">Pricing</button> -->
    <button class="btn col-12 col-md-2 mb-1" :class = "this.$root.currPage==3?'btn-success':'btn-primary'" @click="this.$root.currPage = 3">contact</button>
  </div>
  {{ activePage }}
</template>

<script>
export default {
  name: 'MainNavComponent',
  
  

  props: {
    msg: String,
    activePage: String,
  },
  data() {
    return {
      imgBaseDir: "/img/",
      logo: "/img/logo01.png",
    }
  },
    methods:{
}
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
.hello{
  display:flex;
  width:80vw;
  margin:100px auto;
  justify-content: center;
  flex-direction: column;
  gap:20px;
  }
.pageNav {
  width:80vw;
  gap:15px;
  margin: 0 auto; 
}
.landingNav {
  width:100%;
  display: flex;
  justify-content: center;
  gap:20px;
}

a {
  color: #42b983;
}

img {
  height: 60vh;
  align-self: center;
  flex: 0 0 auto;

  
}</style>
